.Main-Menu {
  display: table;   /* Allow the centering to work */
  margin: 0 auto;
  animation: float2 6s ease-in-out infinite;
  font-family: "Coolvetica";
  font-size:22px;
  margin-top: -16px;
}

.Main-Menu a {
  text-decoration: none;
  color:white;
}

.Main-Menu a:visited {
  text-decoration: none;
  color:white;
}

.Main-Menu a:hover {
  color:red;
}

.Main-Menu ul {
  display: inline-block;
   margin: 0;
   padding: 0;
   zoom:1;
   *display: inline;
  color:white;
  margin-bottom:16px;
}

.Main-Menu li {
  color:white;
  margin-left: 8px;
  display: inline;
}

.Main-Menu li:hover {
  color:red;
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-5px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes float2 {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-3px);
	}
	100% {
		transform: translatey(0px);
	}
}
