.App-header {
  /* background-color: #282c34; */
  /* min-height: 10vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* margin-top: 24px; */
}

.App-header p {
  margin:0;
}

.Logo-Text {
  color:white;
  /* text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #00e6bc, 0 0 40px #000000, 0 0 50px #2e00e6, 0 0 60px #000000, 0 0 70px #ff0c0c; */
  font-family: "Coolvetica";
  /* transform:rotateZ(15deg); */
  padding:0;
  margin:8px;
  font-size:90px;
  max-width:80%;
  animation: float 6s ease-in-out infinite;
}

.App-logo {
  /* height: 40vmin; */
}

@media screen and (orientation:portrait) {
  .Discord-Logo {
    width:32px!important;
    float:right;
    display:block;
    position: absolute;
    margin-top:-8px;
    transition: 250ms;
    margin-left:4px;
    /* animation: PulsingZoom 5s ease infinite; */
  }

  .Twitter-Logo {
    width:24px!important;
    float:right;
    display:block;
    position: absolute;
    transition: 250ms;
    margin-left:14px!important;
    top: 32px!important;
    filter:invert(1);
    /* animation: PulsingZoom 10s ease infinite; */
    pointer-events: all;
  }
}

.Discord-Container {
  width:100%;
  display: block;
  position:absolute;
  height:64px;
  margin-top:-8px;
  float:right;
  padding-left:8px;
  pointer-events: none;
}

.Discord-Text {
  float:left;
  font-size:12px;
  margin-top:64px;
  margin-left:8px;
}

.Discord-Logo {
  width:64px;
  float:right;
  display:block;
  position: absolute;
  transition: 250ms;
  margin-left:8px;
  /* animation: PulsingZoom 5s ease infinite; */
  pointer-events: all;
}

.Twitter-Logo {
  width:48px;
  float:right;
  display:block;
  position: absolute;
  transition: 250ms;
  margin-left:16px;
  top: 72px;
  filter:invert(1);
  /* animation: PulsingZoom 10s ease infinite; */
  pointer-events: all;
}

@keyframes PulsingZoom {
    0%{transform:rotateZ(-5deg);}
    50%{transform:rotateZ(5deg);}
    100%{transform:rotateZ(-5deg);}
}

.Discord-Logo:hover {
  transform:rotateZ(5deg);
}

.Twitter-Logo:hover {
  transform:rotateZ(-5deg);
}

.Discord-No-Styling {
  text-decoration: none;
}

.Discord-No-Styling:hover {
  color:white!important;
}

@keyframes PulsingZoom {
    0%{transform:rotateZ(-5deg);}
    50%{transform:rotateZ(5deg);}
    100%{transform:rotateZ(-5deg);}
}
