@media only screen and (orientation: landscape) and (max-width: 812px) {
  .AboutTicker {
    width: 100%;
  }
  .Main-Information{
    width:100%;
    margin-top:-32px;
  }
}

@media only screen and (orientation: landscape) and (max-width: 768px) {
  .AboutTicker {
    width: 100%;
  }
  .Main-Information{
    width:100%;
    margin-top:-24px;
  }
}

@media screen and (orientation:portrait) {
  canvas {
    width:100%;
    height:100vh;
  }
  .Main-Information {
      width: 100%;
  }
  .Main-Information h3 {
    font-size: 32px;
  }
}

@media screen and (max-width: 1366px)  {
  .Main-Information {
    width:40%;
  }
}

.Main-Information {
  font-family: "Coolvetica";
  display: block;
  opacity: 1;
  z-index: 999999;
  height: 60vh;
  color: white;
  margin: auto;
  width: 30%;
  text-align: center ;
  margin-top:20vh;
}

.Main-Information h3 {
  font-size: 42px;
  z-index:9999;
}

.Main-Information p {
  margin-left:8px;
}
