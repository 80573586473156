.Game-Banner {
  max-width:90%;
  width:25vw;
  margin-top:-20vh;
}

.Game-Link:hover {
  color:red;
}

.Game-Banner:hover {
  -webkit-filter: saturate(1);
   filter: saturate(1);
}

@media only screen and (orientation: landscape) and (max-width: 812px) {
  .Game-Banner {
    max-width:256px;
    /* margin-top:18px; */
    margin-top:-20vh;
  }
}
