.App {
  text-align: center;
  overflow:hidden
}

@font-face {
  font-family: 'Coolvetica';
  font-style: normal;
  font-weight: 400;
  src:
    local('Coolvetica'),
    url('./font/coolvetica rg.ttf') format('truetype')
}

html {
  font-family: 'Coolvetica';
}

body {
  background: #000000;
  overflow:hidden;
}

a {
  color:white;
  text-decoration: none;
}

a:hover{
  color:red;
}

a:visited {
  color:white;
}

@keyframes auroraOpacity {
	0% {
	   opacity:0.2;
	}
	50% {
		opacity: 0.0;
	}
  50% {
    opacity: 0.2;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

.App-link {
  color: #09d3ac;
}

p {
  color:white;
}
