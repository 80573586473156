@media only screen and (orientation: landscape) and (max-width: 768px) {
  .Forest-Footer {
    /* margin-top:10%; */
    /* display:none; */
    /* transform: translateY(-80%)!important; */
    background-size:contain;
    left:-200px!important;
    /* width:50%!important; */
  }
}

.Rocket-Sprite {
  width:125px;
}

.Rocket-Container{
  /* -webkit-transform: rotate(300deg); */
  transform:rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  height:3000px;
  -webkit-animation-name: spin;
 -webkit-animation-duration: 35000ms;
 -webkit-animation-iteration-count: infinite;
 -webkit-animation-timing-function: linear;
 -moz-animation-name: spin;
 -moz-animation-duration: 35000ms;
 -moz-animation-iteration-count: infinite;
 -moz-animation-timing-function: linear;
 -ms-animation-name: spin;
 -ms-animation-duration: 35000ms;
 -ms-animation-iteration-count: infinite;
 -ms-animation-timing-function: linear;
 -o-transition: rotate(3600deg);
 overflow:hidden;
}

.Forest-Footer {
  pointer-events: none;
  display:block;
  width:100%;
  height:100vh;
  margin: 0 auto;
  transform:translateY(-45%);
  background-image:url("../img/forest.png");
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  -webkit-animation: ForestTint 100s ease infinite;
  -moz-animation: ForestTint 100s ease infinite;
  animation: ForestTint 100s ease infinite;
}

@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(45deg);}
    to {transform:rotate(405deg);}
}

@-webkit-keyframes ForestTint {
  0%{filter: brightness(50%)}
  50%{filter: brightness(100%)}
  100%{filter: brightness(50%)}
}
@-moz-keyframes ForestTint {
  0%{filter: brightness(50%)}
  50%{filter: brightness(100%)}
  100%{filter: brightness(50%)}
}
@keyframes ForestTint {
  0%{filter: brightness(50%)}
  50%{filter: brightness(100%)}
  100%{filter: brightness(50%)}
}
